// Default Einstellungen
@import 'wupit-framework/src/scss/settings';


// Partials
@import 'wupit-framework/src/scss/core/base';
@import 'wupit-framework/src/scss/core/borders';
@import 'wupit-framework/src/scss/core/buttons';
@import 'wupit-framework/src/scss/core/colors';
@import 'wupit-framework/src/scss/core/forms';
@import 'wupit-framework/src/scss/core/helper';
@import 'wupit-framework/src/scss/core/icons';
@import 'wupit-framework/src/scss/core/images';
@import 'wupit-framework/src/scss/core/links';
@import 'wupit-framework/src/scss/core/lists';
@import 'wupit-framework/src/scss/core/print';
@import 'wupit-framework/src/scss/core/spacing';
@import 'wupit-framework/src/scss/core/typo';

.wup-list-icons {

	li {
		padding-left: 1.75em;
		position: relative;

		i {
			width: 1.1em;
			text-align: center;
			position: absolute;
			left: 0;
			top: 50%;
			margin-top: -.54em;
		}
	}
}
