.is--interactive-map {
	position: relative;

	.is--marker-wrapper {
		display: block;

        .prj--vh-map-marker-item {
          position: absolute;
          margin-left: -9px;
          margin-top: -9px;
          height: 18px;
          width: 18px;
          left: 0;
          top: 0;

          @media(min-width: $breakpoint-xlarge) {
              margin-left: -12px;
              margin-top: -12px;
              height: 24px;
              width: 24px;
          }

          .prj--vh-map-marker-item-trigger {
            background: map-get($wup-color-project, red);
            transition: background .2s ease-in;
            border-radius: 999px;
            position: relative;
            text-align: center;
            color: $wup-white;
            padding-top: 4px;
            cursor: pointer;
            font-size: 10px;
            display: block;
            height: 100%;
            width: 100%;
            z-index: 1;

            @media(min-width: $breakpoint-xlarge) {
                padding-top: 5px;
                font-size: 14px;
            }

            &.prj--is-equal,
            &.prj--is-pulse {
              &:after {
                content: '';
                position: absolute;
                display: block;
                top: 0;
                bottom: 0;
                left: 0;
                right: 0;
                border-radius: 100%;
                background: rgba(0,0,0,.6);
                opacity: 0;
                animation-name: spotPulse;
                animation-duration: .4s;
                animation-iteration-count: 1;
                animation-direction: linear;
                z-index: 0;
              }
            }


            &:hover,
            &.prj--is-equal {
              background: map-get($wup-color-project, blue);
            }

            &.prj--is-active {
              transform: rotate(45deg);
              animation-name: rotateThis45;
              animation-duration: .3s;
            }
          }

          &.prj--is-mini {
            background: map-get($wup-color-project, red);
            border-radius: 999px;
            margin-left: -4px;
            margin-top: -4px;
            height: 8px;
            width: 8px;

            @media(min-width: $breakpoint-xlarge) {
                margin-left: -6px;
                margin-top: -6px;
                height: 12px;
                width: 12px;
            }
          }

          .prj--vh-map-marker-item-content {
            background: map-get($wup-color-project, blue);
            padding: 0 10px;
            margin-top: -71.5px;
            position: absolute;
            color: $wup-white;
            font-size: .7em;
            display: none;
            left: -190px;
            width: 180px;
            z-index: 10;
            top: 50%;

			@media(min-width: $breakpoint-small) {
				left: -260px;
	            width: 250px;
            }

            @media(min-width: $breakpoint-medium) {
                left: -305px;
                width: 290px;
            }

            @media(min-width: $breakpoint-xlarge) {
                left: -495px;
                width: 480px;
                padding: 12px 18px 12px 170px;
                font-size: .85em;
            }

            &:after {
              border-top: 12px solid transparent;
            	border-left: 12px solid map-get($wup-color-project, blue);
            	border-bottom: 12px solid transparent;
              position: absolute;
              margin-top: -12px;
              right: -12px;
              content: '';
              height: 0;
              top: 50%;
              width: 0;
            }

            p,
            h4 {
              margin: 2px 0;

              @media(min-width: $breakpoint-medium) {
                  margin: 8px 0;
              }
            }

			h5 {
				@media(max-width: $breakpoint-mini-max) {
					font-size: 1rem;
				}
			}

			.is--description {
				display: none;

				@media(min-width: $breakpoint-small) {
					display: block;
				}
			}

            .prj--vh-map-marker-item-content-img {
                display: none;

              @media(min-width: $breakpoint-xlarge) {
                  background-position: center center;
                  background-repeat: no-repeat;
                  background-size: cover;
                  position: absolute;
                  display: block;
                  height: 100%;
                  width: 150px;
                  left: 0;
                  top: 0;
              }
            }


            // Alternative Positionen


			&.prj--is-right {
				left: initial !important;
				right: -190px;

				@media(min-width: $breakpoint-small) {
					left: -260px;
	            }

				@media(min-width: $breakpoint-medium) {
	                right: -305px;
	            }

	            @media(min-width: $breakpoint-xlarge) {
	                right: -495px;
	            }

				&:after {
					border-left: 0;
					border-right: 12px solid map-get($wup-color-project, blue);
					right: initial;
					left: -12px;
					content: '';
	            }
			}

            &.prj--is-top {
              top: initial !important;
              left: -87px !important;
              bottom: 30px;

			  @media(min-width: $breakpoint-small) {
				  left: -122px !important;
			  }

              @media(min-width: $breakpoint-medium) {
                  left: -142px !important;
              }

              @media(min-width: $breakpoint-xlarge) {
                  left: -234px !important;
                  bottom: 40px;
              }

              &:after {
                border-bottom: 0;
                border-right: 12px solid transparent;
                border-top: 12px solid #0f2455;
                border-left: 12px solid transparent;
                right: initial !important;
                top: initial !important;
                margin-left: -6px;
                margin-top: 0;
                bottom: -12px;
                left: 50%;
              }
            }


            &.prj--is-bottom {
              margin-top: 0 !important;
              left: -87px !important;
              top: 30px;

			  @media(min-width: $breakpoint-small) {
				  left: -122px !important;
			  }

              @media(min-width: $breakpoint-medium) {
                  left: -142px !important;
              }

              @media(min-width: $breakpoint-xlarge) {
                  left: -234px !important;
                  top: 40px;
              }

              &:after {
                border-top: 0;
                border-right: 12px solid transparent;
                border-bottom: 12px solid #0f2455;
                border-left: 12px solid transparent;
                right: initial !important;
                margin-left: -6px;
                margin-top: 0;
                top: -12px;
                left: 50%;
              }
            }
          }
        }
	}
}
