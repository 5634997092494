.header-logo {

	img {
		max-width: 295px;
	}
}

.prj--opening-hours {
	padding-left: 30px;
	position: relative;
	float: right;

	i {
		position: absolute;
		left: 0;
		top: .5em;
		font-size: 1.4em;
	}
}
