.slick-prev,
.slick-next {
  border: 0;
  cursor: pointer;
  background: white;
  transition: background .2s ease-in;
  position: absolute;
  text-align: center;
  font-size: 0px;
  height: 44px;
  width: 44px;
  border-radius: 44px;
  z-index: 1;
  top: 50%;
  margin-top: -22px;

  @media(min-width: $breakpoint-medium) {
    width: 60px;
    height: 60px;
    border-radius: 60px;
    margin-top: -30px;
  }

  &:hover {
    background: map-get($wup-color-project, blue);

    &:before { color: $wup-white; }
  }

  &:before {
    font-family: "FontAwesome";
    color: map-get($wup-color-project,blue);
    font-size: 28px;
  }
}

.slick-prev {
  left: 10px;

  &:before {
    content: $fa-var-angle-left;
  }
}

.slick-next {
  right: 10px;

  &:before {
    content: $fa-var-angle-right;
  }
}



.slick-slide {
  position: relative;
}



.slick-slider {

  &:not(.slick-initialized) {

    & > div {
      display: none;

      &:first-child {
        display: block;
      }
    }
  }
}



.slick-dots {
  list-style-type: none;
  padding: 0;
  margin: 0;
  text-align: center;

  li {
    display: inline-block;
    margin-right: 8px;

    button {
      background: map-get($wup-color-project, blue);
      border: 0;
      width: 14px;
      height: 14px;
      font-size: 0px;
      transition: background .2s ease-in;
      cursor: pointer;
      border-radius: 14px;

      &:hover {
        background: map-get($wup-color-project, blue-light);
      }
    }

    &.slick-active {

      button {
        background: map-get($wup-color-project, red);
      }
    }
  }
}
