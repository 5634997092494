.prj--fixed-element {
  position: fixed;
  bottom: .5em;
  right: .5em;
  z-index: 999;

  @media(min-width: $breakpoint-small) {
    bottom: 1em;
    right: 1em;
  }

  &.prj--fixed-element-left {
    right: initial;
    left: .5em;

    @media(min-width: $breakpoint-small) {
      left: 1em;
    }
  }
}

/*
.prj--anchor-nav {
  display: none;

  .prj--show-nav {
    position: relative;
    z-index: 1;
  }

  .prj--anchor-nav-list-wrap {
    display: none;
    position: absolute;
    bottom: 52px;
    left: 0;

    @media(min-width: $breakpoint-small) {
      bottom: 58px;
    }

    @media(min-width: $breakpoint-large) {
      bottom: 62px;
    }

    &:before {
      border-top: 12px solid map-get($wup-color-project, blue);
      border-right: 12px solid transparent;
      border-left: 12px solid transparent;
      position: absolute;
      content: '';
      bottom: -12px;
      left: 12px;
      height: 0;
      width: 0;

      @media(min-width: $breakpoint-small) {
        left: 18px;
      }
    }
  }

  .prj--anchor-nav-list {
    list-style-type: none;
    margin: 0;
    padding: 0;
    max-height: 70vh;
    overflow-y: scroll;
    -webkit-overflow-scrolling: touch;
    background: map-get($wup-color-project, blue);

    li {
      font-size: 13px;
      white-space: nowrap;

      @media(min-width: $breakpoint-small) {
        font-size: 14px;
      }

      a {
        background: map-get($wup-color-project, blue);
        border-top: 1px solid map-get($wup-color-project, bluedark);
        letter-spacing: normal;
        text-transform: none;
        font-weight: normal;
        color: $wup-white;
        padding: 5px 14px;
        display: block;
        white-space: nowrap;
        max-width: 290px;
        text-overflow: ellipsis;
        overflow: hidden;

        &:before {
          font-family: 'FontAwesome';
          content: $fa-var-angle-right;
          margin-right: 6px;
          color: $wup-white;
        }

        &:hover,
        &.prj--is-active {
          background: map-get($wup-color-project, bluedark);

          &:before {
            color: map-get($wup-color-project, red);
          }
        }
      }

      &.prj--is-headline {
        background: map-get($wup-color-project, bluedark);
        font-family: map-get($wup-fonts, officinasansbold);
        padding: 10px 14px;
        color: $wup-white;
        text-transform: uppercase;
        letter-spacing: 2px;
        font-size: 16px;

        i {
          color: map-get($wup-color-project, red);
        }
      }
    }
  }
}
*/
