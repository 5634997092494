.prj--icon-map-drop {
    position: relative;
    width: 1.25em;
    height: 1em;

    &:before {
        position: absolute;
        left: 0;
        bottom: -.45em;
    }

    &:after {
        font-family: "FontAwesome";
        content: $fa-var-map-marker;
        position: absolute;
        top: -.2em;
        left: .25em;
        font-size: 1.2em;
    }
}



#nav {
  padding: 3px 0;
  display: inline-block;
  width: 100%;
  background: $wup-white;


  .prj--interactive-map-icon {
      background: map-get($wup-color-project, red);
      float: left;
      width: 5%;
      border-right: 2px solid #fff;
      padding-left: 0;
      padding-right: 0;
      overflow: hidden;

      i {
          margin-right: 0;
      }

      &:hover {

          .prj--icon-map-drop {

              &:before {
                  animation-name: animateBefore;
                  animation-duration: .5s;
                  //bottom: -.6em;
              }

              &:after {
                  animation-name: animateAfter;
                  animation-duration: .5s;
                  //top: -.6em;
              }
          }
      }
  }

    @keyframes animateBefore {
        //from {bottom: -.4em;}
        //to {bottom: -.6em;}

        0%   {bottom: -.45em;}
        50%  {bottom: -.8em;}
        100% {bottom: -.45em;}
    }

    @keyframes animateAfter {
        //from {top: -.3em;}
        //to {top: -.6em;}

        0%   {top: -.2em;}
        50%  {top: -.45em;}
        100% {top: -.2em;}
    }


  ul.has--interactive-map {

      li {
          width: 17.4%;

          @media(min-width:$breakpoint-large) {
            width: 18%;
          }
      }
  }


  @keyframes showMapIcon {
      from {width: 0;}
      to {width: 5%;}
  }


  a {
    background: map-get($wup-color-project, blue);
    display: inline-block;
    text-align: center;
    padding: 15px 4px;
    color: $wup-white;

    &:hover {
      background: map-get($wup-color-project, bluedark);
    }
    &:active {
      background: map-get($wup-color-project, bluelight);
    }


    // Virtuelles Haus

    // &.prj--virtual-house {
    //   background: map-get($wup-color-project, red);
    //   float: left;
    //   width: 49.75%;

    //   @media(min-width: $breakpoint-medium) {
    //     width: 24%;
    //   }

    //   @media(min-width: $breakpoint-large) {
    //     width: 20%;
    //   }

    //   &:hover {
    //     background: map-get($wup-color-project, reddark);
    //   }
    //   &:active {
    //     background: map-get($wup-color-project, redlight);
    //   }
    // }


    // Mobile Nav Toggle

    &.prj--mobile-nav {
      //margin-left: .5%;
      //width: 49.75%;
      width: 80%;
      float: left;
    }

    &.prj--interactive-map-mobile {
        background: map-get($wup-color-project, red);
        float: left;
        border-right: 2px solid #fff;
        text-align: center;
        padding-left: 0;
        padding-right: 0;
        width: 20%;
    }
  }


  a:before {
	  font-family: 'FontAwesome';
  }


  // Aktiver Menüpunkt

  a.prj--is-active,
  .prj--is-active a,
  .current-menu-item a {
    background: map-get($wup-color-project, bluedark);
    position: relative;

	&.has--children:before {
      font-family: 'FontAwesome';
      content: $fa-var-angle-down;
      line-height: 1;
      text-align: center;
      position: absolute;
      bottom: -18px;
      left: 50%;
      width: 100%;
      height: 18px;
      width: 90px;
      margin-left: -45px;
      background: map-get($wup-color-project, red);
      z-index: 999;
      border-radius: 0 0 5px 5px;
    }
  }


  // Navigation List

  #nav-main {
      background: map-get($wup-color-project, blue);
      position: relative;
    display: none;
    float: left;
    width: 100%;
    //margin-bottom: 8px;

    @media(min-width: $breakpoint-medium) {
      display: block;
    }
  }

  ul {
    list-style-type: none;
    padding: 0;
    display: block;
    margin: 0;

    li {
      border-right: 1px solid map-get($wup-color-project, bluedark);
      //position: relative;
      float: left;
	  position: relative;
      width: 18.4%; // 100% - [Home-Button-Breite (8%)] / 5 Navigations-Elemente
      @media(min-width:$breakpoint-large) {
        width: 19%; // 100% - [Home-Button-Breite (5%)] / 5 Navigations-Elemente
        }

      &:last-child {
        border-right: 0;
      }


      &.is--hovered {

        a {

          &:before {
            background: map-get($wup-color-project, bluedark) !important;
          }
        }

        ul {
          display: block;
        }
      }


      a {
        width: 100%;
      }


      &.prj--virtual-house-mobile {
        display: none;
      }


      // Home Links

      /*&.prj--home-link {
        width: 8% !important;
        position: relative;

        @media(min-width:$breakpoint-large) {
          width: 5% !important;
        }

        a {
          font-size: 0px;

          &:after {

            @media(min-width: $breakpoint-medium) {
              font-size: $wup-font-size-tablet;
              font-family: "FontAwesome";
              content: $fa-var-home;
              margin-left: -16px;
            }

            @media(min-width: $breakpoint-large) {
              font-size: $wup-font-size-desktop;
            }
          }
        }

        ul {
          display: none !important;
        }
      }
	  */

	  // Home Links

      &.prj--home-link {
        width: 8%;
        position: relative;

		@media(min-width:$breakpoint-large) {
          width: 5% !important;
        }

        a {
          font-size: 0px;

          &:before {
            border-radius: 0;
            left: 0;
            margin-left: 0;
            width: 100%;
            height: 4px;
            bottom: -4px;
          }

          &:after {

            @media(min-width: $breakpoint-medium) {
              font-size: $wup-font-size-tablet;
              font-family: "FontAwesome";
              content: $fa-var-home;
              margin-left: -16px;
            }

            @media(min-width: $breakpoint-large) {
              font-size: $wup-font-size-desktop;
            }
          }
        }

        ul {
          display: none !important;
        }
      }

    }
  }

  /*
  ul.prj--anchor-nav-list {
      background: map-get($wup-color-project, lightbluelight);
    //border-bottom: 2px solid map-get($wup-color-project, blue);
    text-align: center;
    padding: 0 10px;
    float: left;
    width: 100%;

    li {
        float: none;
        display: inline-block;
        width: auto;
        margin: 0;
        border: 0 !important;

        a {
            color: map-get($wup-color-project, blue);
            background: none !important;
            //font-family: map-get($wup-fonts, officinasans);
            text-transform: none;
            letter-spacing: normal;
            padding: 16px 8px 12px;
            font-size: .9em;

            &.prj--is-active {
                color: map-get($wup-color-project, red);

                &:before {
                    display: none;
                }
            }
        }
    }
  }
  */


  ul.prj--anchor-nav-list {
    background: map-get($wup-color-project, blue);
    padding: 0;
    position: absolute;
    padding-top: 22px;
    display: none;
    z-index: 555;
    overflow-y: scroll;
    -webkit-overflow-scrolling: touch;
    max-height: 620px;
    max-height: 88vh;

	&.is--hovered {
		display: block;
	}

    li {
  	border-right: 0;
  	width: 100%;

  	&:first-child {

  	  a {
  		border-top: 0;
  	  }
  	}

  	a {
  	  text-align: left;
  	  text-transform: none;
  	  letter-spacing: 0;
  	  font-size: $wup-font-size-small;
  	  padding: 8px 10px 8px 28px;
  	  border-top: 1px solid map-get($wup-color-project, bluedark);
  	  background: map-get($wup-color-project, blue) !important;
  	  position: relative;

  	  &:before {
		  position: absolute;
  		content: $fa-var-angle-right !important;
  		margin-right: 6px !important;
  		color: $wup-white !important;
  		background: none !important;
  		left: 14px !important;
  		width: auto !important;
  		bottom: inherit !important;
  		height: 22px !important;
  		top: 50%;
  		margin-top: -11px;
  		margin-left: 0 !important;
  	  }

  	  &:hover,
  	  &.prj--is-active {
  		background: map-get($wup-color-project, bluedark) !important;

  		&:before {
  		  color: map-get($wup-color-project, red) !important;
  		}
  	  }
  	}
    }
  }



}



// Off Canvas

.mm-menu {

  * {
    font-family: inherit;
  }

  .prj--virtual-house-mobile {

    a {
      color: map-get($wup-color-project, red);
    }
  }
}
