
// Auf der Seite Sichtbare Galerie-Elemente
.prj--gallery-item {
	height: 0;
	position: relative;
	box-sizing: border-box;
	padding-bottom: 75%;

	&:after {
		content: "";
		position: absolute;
		width: 100%;
		height: 100%;
	}

	.img-wrapper {
		background: red;
		position: absolute;
		@include all-directions(5px);
		overflow: hidden;

		img {
			display: block;

			min-height: 100%;
		    min-width: 100%;
		    height: auto;
		    width: auto;
		}
	}
}

.prj--gallery-grid { overflow: hidden; }
.prj--gallery-item-container {
	float: left;
	&.big {
		width: 50%;
	}
	&.small {
		width: 25%;
	}
}



// Trigger-Button, der die Lightgallery öffnet
.prj--gallery-trigger-container {
	position: relative;

	// Linie hinter dem Button
	&:after {
		content: "";
		position: absolute;
		left: 0;
		right: 0;
		top: 50%;
		margin-top: -1px;
		height: 2px;
		background: map-get($wup-color-project,blue);
	}

	// Button
	.prj--gallery-trigger {
		z-index: 2;
		@include border-y(20px,solid,white);
	}
}
