@mixin all-directions($value) {
	top: $value;
	left: $value;
	right: $value;
	bottom: $value;
}

@mixin border-y($width,$style,$color) {
	border-left-width: $width;
	border-left-style: $style;
	border-left-color: $color;

	border-right-width: $width;
	border-right-style: $style;
	border-right-color: $color;
}