#wrapper {
  background: $wup-white;
  max-width: 1780px;
  min-height: 100vh;
  margin: 0 auto;
  padding: 8px;
}

.uk-container {
  padding: 0;
}


.prj--icon-spin:before {
  content: $fa-var-cog !important;
}

.prj--icon-right {
    margin-right: 0;
    margin-left: 6px;
}


footer {
    padding-bottom: 50px;
}

@media(max-width: $breakpoint-small) {
    h1, .wup-h1 {
        font-size: 2.2rem;
    }

    h2, .wup-h2 {
        font-size: 1.5rem;
    }

    h3, .wup-h3 {
        font-size: 2.0rem;
    }

    h4, .wup-h4 {
        font-size: 1.5rem;
    }

    h5, .wup-h5 {
        font-size: 1.4rem;
    }

    h6, .wup-h6 {
        font-size: 1.0rem;
    }
}




/* Padding Bottom Helper Classes */
.padding-bottom-percent-10 { padding-bottom: 10% !important; }
.padding-bottom-percent-15 { padding-bottom: 15% !important; }
.padding-bottom-percent-20 { padding-bottom: 20% !important; }
.padding-bottom-percent-25 { padding-bottom: 25% !important; }
.padding-bottom-percent-30 { padding-bottom: 30% !important; }
.padding-bottom-percent-35 { padding-bottom: 35% !important; }
.padding-bottom-percent-40 { padding-bottom: 40% !important; }
.padding-bottom-percent-45 { padding-bottom: 45% !important; }
.padding-bottom-percent-50 { padding-bottom: 50% !important; }
.padding-bottom-percent-55 { padding-bottom: 55% !important; }
.padding-bottom-percent-56 { padding-bottom: 56.25% !important; }
.padding-bottom-percent-60 { padding-bottom: 60% !important; }
.padding-bottom-percent-65 { padding-bottom: 65% !important; }
.padding-bottom-percent-70 { padding-bottom: 70% !important; }
.padding-bottom-percent-75 { padding-bottom: 75% !important; }
.padding-bottom-percent-80 { padding-bottom: 80% !important; }
.padding-bottom-percent-85 { padding-bottom: 85% !important; }
.padding-bottom-percent-90 { padding-bottom: 90% !important; }
.padding-bottom-percent-95 { padding-bottom: 95% !important; }
.padding-bottom-percent-100 { padding-bottom: 100% !important; }

@media(min-width:$breakpoint-medium) {
  .padding-bottom-percent-medium-10 { padding-bottom: 10% !important; }
  .padding-bottom-percent-medium-15 { padding-bottom: 15% !important; }
  .padding-bottom-percent-medium-20 { padding-bottom: 20% !important; }
  .padding-bottom-percent-medium-25 { padding-bottom: 25% !important; }
  .padding-bottom-percent-medium-30 { padding-bottom: 30% !important; }
  .padding-bottom-percent-medium-35 { padding-bottom: 35% !important; }
  .padding-bottom-percent-medium-40 { padding-bottom: 40% !important; }
  .padding-bottom-percent-medium-45 { padding-bottom: 45% !important; }
  .padding-bottom-percent-medium-50 { padding-bottom: 50% !important; }
  .padding-bottom-percent-medium-55 { padding-bottom: 55% !important; }
  .padding-bottom-percent-medium-56 { padding-bottom: 56.25% !important; }
  .padding-bottom-percent-medium-60 { padding-bottom: 60% !important; }
  .padding-bottom-percent-medium-65 { padding-bottom: 65% !important; }
  .padding-bottom-percent-medium-70 { padding-bottom: 70% !important; }
  .padding-bottom-percent-medium-75 { padding-bottom: 75% !important; }
  .padding-bottom-percent-medium-80 { padding-bottom: 80% !important; }
  .padding-bottom-percent-medium-85 { padding-bottom: 85% !important; }
  .padding-bottom-percent-medium-90 { padding-bottom: 90% !important; }
  .padding-bottom-percent-medium-95 { padding-bottom: 95% !important; }
  .padding-bottom-percent-medium-100 { padding-bottom: 100% !important; }
}





/* - Text Box mit Icon (z.B. für Buttons und die Slider-Key-Facts) - */
.prj--icon-text-box {
  position: relative;
  padding-left: $prj--button-icon-size + 20px;
  font-size: 1rem;
  line-height: 1em;
  text-transform: uppercase;
  letter-spacing: 0.1em;
  font-weight: 400;
  margin: 0;
  display: inline-block;
  position: relative;
  padding: map-get($wup-spaces,verysmall)*1.5 map-get($wup-spaces,verysmall)*2;
  padding-left: $prj--button-icon-size + 20px;
  border: none;

  // Default: Blaue Variante
  &, &.blue {
    background: map-get($wup-color-project,blue);
    color: map-get($wup-color-neutral,white);
  }

  // Weiße Variante
  &.white {
    background: map-get($wup-color-neutral,white);
    color: map-get($wup-color-project,blue);
  }

  // Button Text
  .prj--icon-text-box--text {
    position: relative;
    top: 1px;
  }

  // Icon
  .prj--icon-text-box--icon {
    display: block;
    position: absolute;
    left: map-get($wup-spaces,verysmall);
    top: 50%;
    margin-top: -($prj--button-icon-size/2);

    .prj--icon-text-box--icon-inner {
      display: inline-block;
      height: $prj--button-icon-size;
      width: $prj--button-icon-size;
      border-radius: $prj--button-icon-size;
      position: relative;
      border: 2px solid map-get($wup-color-project,primary);

      .fa {
        position: absolute;
        @include all-directions(0);
        left: 2px;
        top: 50%;
        margin-top: -($prj--button-icon-font-size/2);
        text-align: center;
        font-size: $prj--button-icon-font-size;
        line-height: $prj--button-icon-font-size;
      }
    }
  }
}
