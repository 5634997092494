.prj--story-single {
  background: $wup-white;
  padding: 20px;

  @media(min-width: $breakpoint-medium) {
    padding: 3em;
  }

  &.prj--cross-links {

    a {
      display: inline-block;
      text-transform: none;
      letter-spacing: normal;

      &:after {
        content: ',';
        color: map-get($wup-color-project, blue);
      }

      &:last-child {

        &:after {
          display: none;
        }
      }
    }
  }
}



.prj--story-text {
  display: inline-block;
  width: 100%;
}



.prj--story-header {
  margin: -20px -20px 20px -20px;

  @media(min-width: $breakpoint-medium) {
    margin: -3em -3em 3em -3em;
  }

  img {
    width: 100%;
    height: auto;
    max-width: none;
  }
}



.prj--story-listing {

  .prj--story {
    background: map-get($wup-color-project, lightbluelight);
    margin: 2px 0;

    &:nth-child(2n+2) {
      background: $wup-white;
    }
  }
}


.prj--story-ajax {

  i {
    height: 17px;
    width: 15px;
  }
}
