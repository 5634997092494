.prj--hero {
  position: relative;

  // .slick-slider {
  //   height: 100%;

  //   .slick-list {
  //     height: 100%;

  //     .slick-track {
  //       height: 100%;

  //       .slick-slide {
  //         height: 100%;
  //       }
  //     }
  //   }
  // }


  .prj--fluid-image {
      background-color: map-get($wup-color-project, blue);
    background-position: center center;
    background-repeat: no-repeat;
    background-size: cover;
    min-height: 400px;
  }


  .prj--overlay {
    position: absolute;
    margin-top: -3.2em;
    color: $wup-white;
    font-size: .75em;
    height: 6.4em;
    right: 0;
    top: 50%;
    left: 0;

    @media(min-width: $breakpoint-medium) {
      font-size: 1em;
    }
  }

  .prj--overlay-keyfacts {
    position: absolute;
    @include all-directions(0);
    padding: map-get($wup-spaces,small);

    .uk-container { height: 100%; }
  }
}
