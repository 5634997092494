label {
	font-size: 1em;
}

button, input, optgroup, select, textarea {
    font-family: $wup-font-family-default;
    font-size: 1em;
    line-height: $wup-line-height-default;
    margin: 0;
	color: $wup-body-color;
}

input:not([type="checkbox"]):not([type="radio"]):not([type="submit"]),
textarea,
select {
	//background-color: $wup-white;
	border: 2px solid map-get($wup-color-project, lightbluelight);
	border-radius: 0;
	padding: 1em 1.2em;

	&:focus {
		border-color: map-get($wup-color-project, blue);
	}
}


// FLIPPING FORM

// HEADER

.prj--nav-synced-nav {
	counter-reset: section;

	li {

		.prj--slider-trigger {
			font-size: 1.1em;
			//color: $wup-white;
			font-family: map-get($wup-fonts, officinasansbold);
			text-transform: uppercase;
			letter-spacing: 2px;

			&[onclick] {
				cursor: pointer;
			}

			&:before {
				counter-increment: section;
    			content: counter(section);
				background: map-get($wup-color-project, blue);
				color: $wup-white;
				display: inline-block;
				width: 50px;
				height: 50px;
				text-align: center;
				font-size: 22px;
				border-radius: 999px;
				padding-top: 11px;
				margin-right: 10px;
			}

			/*
			&.prj--overview {
				color: map-get($wup-color-project, lightbluedark);

				&:before {
					background: map-get($wup-color-project, lightbluedark);
				}
			}
			*/

			&.prj--active {
				color: map-get($wup-color-project, red);

				&:before {
					background: map-get($wup-color-project, red);
				}
			}
		}
	}
}

// FORM

.prj--flippingform {
	//text-align: center;
	padding-left: 1em;
	padding-right: 1em;

	@media(min-width: $breakpoint-xlarge) {
		padding-left: 0;
		padding-right: 0;
	}


	fieldset {
		//background: #eee;
		border: 0;
		//margin-bottom: 5em;
	}


	h3 {
		margin-bottom: .6em;
		font-size: 1.8em;
	}


	// Optionale Eingaben

	.prj--optional {
		display: none;
		margin-top: 1em;
		//background: map-get($wup-color-project, lightbluedark);
		padding: 1em 1.5em;
		border: 2px dashed $wup-white;

		h5 {
			color: map-get($wup-color-project, red);
		}

		input[type=checkbox],
		input[type=radio],
		label::before {
			width: 34px;
			height: 34px;
			margin-top: -20px;
		}

		svg {
			width: 24px;
    		height: 24px;
			margin-top: -15px;
		}

		label {
			padding: 0 0 0 50px;
		}
	}
}


#prj--ff-check-output {

	.prj--ff-check-wrap {
		margin-bottom: 2em;

		h3 {
			color: map-get($wup-color-project, red);
			margin-bottom: 10px;
		}

		dl {
			margin: .5em 0;

			dd {
				margin-left: 0;
			}
		}
	}
}
